import React from "react";
import LogoLink from "@insightfulscience/atomic-react/LogoLink";
import routes from "../routes";

export const logoPath = "/images/graphpad/logo/logo-graphpad-white.svg";
export const logoAlt = "GraphPad by Dotmatics";

const prerenderedLogo = (
	<LogoLink
		href={routes.external.index}
		imgSrc={logoPath}
		alt={logoAlt}
		data-qa-selector="header-logo"
		className="max-w-45"
	/>
);

export default prerenderedLogo;
