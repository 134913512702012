import React from "react";
import { bool } from "prop-types";
import Header from "@insightfulscience/atomic-react/Header";
import Navbar from "@insightfulscience/atomic-react/Navbar";
import NavbarItem from "@insightfulscience/atomic-react/Navbar/Item";
import NavbarItemLink from "@insightfulscience/atomic-react/Navbar/Link";
import NavbarSearch from "@insightfulscience/atomic-react/NavbarSearch";
import Button from "@insightfulscience/atomic-react/Button";
import Icon from "@insightfulscience/atomic-react/Icon";
import { useLocale } from "@insightfulscience/smart-react/i18";
import Form from "@insightfulscience/atomic-react/Form";
import Input from "@insightfulscience/atomic-react/Input";
import TopBar from "@insightfulscience/atomic-react/TopBar";
import TopBarLogo from "@insightfulscience/atomic-react/TopBar/Logo";
import TopBarNav from "@insightfulscience/atomic-react/TopBar/Nav";
import Image from "@insightfulscience/atomic-react/Image";
import Link from "@insightfulscience/atomic-react/Link";
import TopBarDropdown from "@insightfulscience/atomic-react/TopBar/Dropdown";
import Block from "@insightfulscience/atomic-react/Block";
import NextLink from "@insightfulscience/smart-react/NextLink";
import NavbarDropdown from "@insightfulscience/atomic-react/NavbarDropdown";
import NavbarDropdownTitle from "@insightfulscience/atomic-react/NavbarDropdown/Title";
import NavbarDropdownMenu from "@insightfulscience/atomic-react/NavbarDropdown/Menu";
import routes from "../routes";
import prerenderedLogo from "../PrerenderedLogo";
import PlatformTopBar from "./PlatformTopBar";
import ProductsTopBar from "./ProductsTopBar";
import { PrismDropdown } from "./PrismDropdown";

const SiteHeader = ({ isAuthorized = false }) => {
	const [t] = useLocale("header");
	return (
		<>
			<TopBar>
				<TopBarLogo>
					<Link href={routes.external.dotmatics} target="_blank">
						<Image src="/images/common/dotmatics-logo-white.svg" />
					</Link>
				</TopBarLogo>
				<TopBarNav>
					<TopBarDropdown isMegaMenu>
						<TopBarDropdown.Title
							triangle
							triangleProps={{ color: "dotmatics-2", isOpenColor: "dotmatics-2" }}
						>
							{t("PLATFORM_PRODUCTS")}
						</TopBarDropdown.Title>
						<TopBarDropdown.Body
							utility={{
								bgColor: "dotmatics-6",
								border: {
									top: { width: 1, color: "white--20" },
									bottom: { width: 1, color: "white--20" },
								},
							}}
							spacing={{ my: 2 }}
						>
							<PlatformTopBar />
							<ProductsTopBar />
						</TopBarDropdown.Body>
					</TopBarDropdown>
				</TopBarNav>
			</TopBar>
			<Header logo={prerenderedLogo}>
				<Block utility={{ lg: { display: "flex", justify: "end" } }} sizing={{ lg: { w: "full" } }}>
					<Navbar inverted nav="primary">
						<NavbarDropdown isNotClosed isMegaMenu>
							<NavbarDropdownTitle>{t("PRISM")}</NavbarDropdownTitle>
							<NavbarDropdownMenu isList={false}>
								<PrismDropdown />
							</NavbarDropdownMenu>
						</NavbarDropdown>
						<NavbarItem>
							<NavbarItemLink Tag={NextLink} href={routes.enterprise}>
								{t("ENTERPRISE")}
							</NavbarItemLink>
						</NavbarItem>
						<NavbarItem>
							<NavbarItemLink Tag={NextLink} href={routes.resources}>
								{t("RESOURCES")}
							</NavbarItemLink>
						</NavbarItem>
						<NavbarItem>
							<NavbarItemLink href={routes.external.support}>{t("SUPPORT")}</NavbarItemLink>
						</NavbarItem>
						<NavbarItem>
							<NavbarItemLink href={routes.external.howToBuy}>{t("HOW_TO_BUY")}</NavbarItemLink>
						</NavbarItem>
					</Navbar>
					<Navbar nav="secondary" inverted>
						<NavbarItem secondary>
							<NavbarItemLink href={routes.external.cart}>
								<Icon name="shopping-cart" />
								{t("CART")}
							</NavbarItemLink>
						</NavbarItem>
						<NavbarItem secondary>
							<NavbarItemLink href={routes.myAccount} className="nav__item-link">
								<Icon name="user-alt" /> {isAuthorized ? t("MY_ACCOUNT") : t("SIGNIN")}
							</NavbarItemLink>
						</NavbarItem>
						<NavbarSearch>
							<Form method="get" action="/search/">
								<Input
									type="search"
									id="searchquery"
									name="searchquery"
									placeholder="Search..."
									autoComplete="off"
								/>
							</Form>
						</NavbarSearch>
						<NavbarItem secondary className="secondary-nav__item-trial">
							<Button.Link size="s" href={routes.external.freeTrial}>
								<Icon name="arrow-to-bottom" /> {t("FREE_TRIAL")}
							</Button.Link>
						</NavbarItem>
					</Navbar>
				</Block>
			</Header>
		</>
	);
};

SiteHeader.propTypes = {
	isAuthorized: bool,
};

export default SiteHeader;
