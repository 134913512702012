import React from "react";
import Icon from "@insightfulscience/atomic-react/Icon";
import Image from "@insightfulscience/atomic-react/Image";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import { useLocale } from "@insightfulscience/smart-react/i18";
import ButtonTheme from "@insightfulscience/atomic-react/ButtonTheme";
import routes from "../routes";

const PlatformTopBar = () => {
	const [t] = useLocale("header");

	return (
		<>
			<MegaMenu utility={{ justify: "start" }}>
				<MegaMenuCol md={8}>
					<MegaMenuItem>
						<Paragraph sx={{ fontSize: "l", fontColor: "white", fontWeight: "bold" }}>
							{t("TOPBAR.PLATFORM.TITLE")}
						</Paragraph>
						<Paragraph sx={{ fontSize: "s", fontColor: "white" }}>
							{t("TOPBAR.PLATFORM.DESC")}
						</Paragraph>

						<ButtonTheme.Link
							href={routes.external.platform}
							styleType="topbar"
							spacing={{ px: 5, py: 3, mt: 4 }}
							target="_blank"
						>
							{t("TOPBAR.PLATFORM.BTN")}
							<Icon spacing={{ ml: 4 }} name="link-ext" />
						</ButtonTheme.Link>
					</MegaMenuItem>
				</MegaMenuCol>
			</MegaMenu>
			<Image
				src="/images/dotmatics/lab-archives/top-bar/platform.png"
				className="position-absolute top-0 right-0"
			/>
		</>
	);
};

export default PlatformTopBar;
