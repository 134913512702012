import React from "react";
import Footer from "@insightfulscience/atomic-react/Footer";
import FooterContainer from "@insightfulscience/atomic-react/Footer/Container";
import FooterMainContainer from "@insightfulscience/atomic-react/Footer/Main";
import FooterNav, {
	FooterNavCol,
	FooterNavItemLink,
	FooterNavList,
	FooterNavListItem,
	FooterNavListTitle,
	FooterNavRow,
} from "@insightfulscience/atomic-react/Footer/Nav";
import FooterCopyrightV3, {
	FooterCopyrightV3Link,
	FooterCopyrightV3Text,
} from "@insightfulscience/atomic-react/Footer/CopyrightV3";
import FooterLogoV3 from "@insightfulscience/atomic-react/Footer/LogoV3";
import { useLocale } from "@insightfulscience/smart-react/i18";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";
import { logoAlt, logoPath } from "../PrerenderedLogo";

const SiteFooter = () => {
	const [t] = useLocale("footer");

	return (
		<Footer>
			<FooterContainer>
				<FooterMainContainer lg={12}>
					<FooterNav>
						<FooterNavRow>
							<FooterNavCol sm={6} md="auto" lg="auto">
								<FooterNavListTitle>{t("SCIENTIFIC_SOFTWARE")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.graphPadPrism}>
											{t("GRAPHPAD_PRISM")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.freeTrial}>
											{t("PRISM_FREE_TRIAL")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.enterprise}>
											{t("ENTERPRISE")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.customers}>
											{t("PRISM_CUSTOMERS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.instat}>
											{t("INSTAT")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.features.powerAnalysis}>
											{t("STATMATE")}
										</FooterNavItemLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} md="auto" lg="auto">
								<FooterNavListTitle>{t("HOW_TO_BUY")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.howToBuy}>
											{t("PRICING")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.student}>
											{t("STUDENT_PRICING")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.professorCourseLicenses}>
											{t("PROFESSOR_COURSE_LICENSES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.graphPadChina}>
											{t("GRAPHPAD_CHINA")}
										</FooterNavItemLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} md="auto" lg="auto">
								<FooterNavListTitle>{t("DATA_ANALYSIS_RESOURCES")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.quickCalcs}>
											{t("QUICKCALCS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.resources}>
											{t("PRODUCT_TUTORIALS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.statisticsGuide}>
											{t("STATISTICS_GUIDE")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.curveFittingGuide}>
											{t("CURVE_FITTING_GUIDE")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.prismAcademy}>
											{t("PRISM_ACADEMY")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} md="auto" lg="auto">
								<FooterNavListTitle>{t("HELP")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.support}>
											{t("SUPPORT")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.prismUserGuide}>
											{t("PRISM_USER_GUIDE")}
										</FooterNavItemLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} md="auto" lg="auto">
								<FooterNavListTitle>{t("COMPANY")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.about}>
											{t("ABOUT")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.careers}>
											{t("CAREERS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.portfolio}>
											{t("PORTFOLIO")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.dotmatics}>
											{t("DOTMATICS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.external.contacts}>
											{t("CONTACT")}
										</FooterNavItemLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
						</FooterNavRow>
					</FooterNav>
				</FooterMainContainer>
			</FooterContainer>
			<FooterCopyrightV3>
				<FooterLogoV3 href={routes.external.index} logoPath={logoPath} logoAlt={logoAlt} />
				<FooterCopyrightV3Text>
					{t("ALL_RIGHTS_RESERVED", { yyyy: new Date().getFullYear() })}
					<FooterCopyrightV3Link href={routes.external.termsOfService}>
						{t("TERMS")}
					</FooterCopyrightV3Link>
					<FooterCopyrightV3Text.Separate />
					<FooterCopyrightV3Link href={routes.external.privacyPolicy}>
						{t("PRIVACY")}
					</FooterCopyrightV3Link>
					<FooterCopyrightV3Text.Separate />
					<FooterCopyrightV3Link href={routes.external.donotsell}>
						{t("DONOTSELL")}
					</FooterCopyrightV3Link>
				</FooterCopyrightV3Text>
			</FooterCopyrightV3>
		</Footer>
	);
};

export default SiteFooter;
